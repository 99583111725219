import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Post from '../components/Post'
import Intro from '../components/Intro/Intro'
import { SEO } from '../components/SEO'

class IndexRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges.map(post => (
      <Post data={post} key={post.node.fields.slug} />
    ))

    return (
      <Layout>
        <React.Fragment>
          <SEO />
          <Intro data={this.props.data} />
          <div className="content">
            <div className="content__inner">{posts}</div>
          </div>
        </React.Fragment>
      </Layout>
    )
  }
}

export default IndexRoute

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        author {
          name
          email
          twitter
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { layout: { eq: "post" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
          }
        }
      }
    }
  }
`
